import apiClient from '@/utils/api-client'

class StripeService {
    public getPaymentUrl(planId: string) {
        return new Promise<string>((resolve, reject) => {
            apiClient.get('/api/v1/stripe/payment-url/' + planId).then((res) => {
                if (res.status == 200 && res.data != null) {
                    if (res.data.status == 6002) {
                        resolve(res.data.url)
                    } else {
                        resolve("false")
                    }
                } else {
                    resolve("false")
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default new StripeService()
