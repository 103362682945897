<template>
  <Header></Header>
  <div class="main-height">
    <section class="card-select">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img class="lock-img mb-4" src="../assets/lock.png" alt="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <p class="mb-0">Step <span class="fw-bold">3</span> of <span class="fw-bold">3</span>
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <h1 class="fw-bold text-center mt-1">
                  Choose how to pay
                </h1>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <p>
                  Your payment is encrypted, and your card information is never stored on our servers.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <h5>Secure for peace of mind.</h5>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h5 class="text-center">
                No auto-renewals.
              </h5>
            </div>
          </div>
          <div v-if="planId != ''" class="row mt-2">
            <div class="col-12">
              <div class="img-fluid d-flex justify-content-end align-items-center encryption">
                <p class="mt-1 mb-0">End-to-end encrypted</p>
                <img class="secure-server mx-1 my-1" src="../assets/lock2.svg" alt="">
              </div>
            </div>
          </div>
          <div v-else class="alert-danger mt-3 ps-3 pe-3">
            Please choose a plan before continuing with the payment.
          </div>
          <div v-if="planId != ''">
          <div class="row">
            <div class="col-12">
              <div class="underline">
                <a @click="doPayment">
                  <div class="card w-100 payment-card-button" role="button">
                    <div class="row">
                      <div class="col-10">
                        <p class="ms-3 mt-3">
                          <span class="me-2">Credit or Debit Card</span>
                          <img  class="img-fluid cards-img" src="../assets/visa-card.png" alt="">
                        </p>
                      </div>
                      <div class="col-2 my-auto">
                        <div class="card-arrow-img">
                          <img class="card-arrow" src="../assets/arrow.svg" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          </div>
          <div v-else class="row">
            <div class="col-12 mt-4">
              <div class="activated">
                <button class="btn text-white w-100 rounded-1" @click="doPlans">CHOOSE A PLAN</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <Footer></Footer>

  <!-- <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-12">
          <div>
            <img class="lock-img mt-5 mb-3" src="../assets/lock.png" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 text-1">
          Step 3 of 3
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-12 text-2 mt-2">
          Setup your payment
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-12">
          <div class="text-3 mt-2  text-justify">
            Your membership starts as soon as you set up payment.
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-12 col-12 card-text">
          No Commitments.
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-12 card-text">
          Cancel online anytime.
        </div>
      </div>

      <div v-if="planId != ''" class="row mt-3">
        <div class="col-sm-12 col-12">
          <div class="img-fluid d-flex justify-content-end">
            <p class="card-p my-2">Secure Server</p>
            <img class="secure-server me-1 my-1" src="../assets/lock2.svg" alt="">
          </div>
        </div>
      </div>
      <div v-else class="alert-danger mt-3 ps-3 pe-3">
        Please choose a plan before continuing with the payment.
      </div>
      <div v-if="planId != ''" class="card w-100 payment-card-button">
        <a class="mx-2" @click="doPayment">
          Credit or Debit Card
          <svg class="card-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
          <div class="ms-2 mt-2">
            <img width="130px" class="img-fluid" src="../assets/visa-card.png" alt="">
          </div>
        </a>
      </div>
      <div v-else class="row">
        <div class="col-sm-12 col-12 mt-4">
          <button class="btn text-white w-100 rounded-1" @click="doPlans">CHOOSE A PLAN</button>
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import UsersService from "@/services/users-service";
// import ChannelService from "@/services/channel-service";
import AuthService from '@/services/auth-service'
import StripeService from "@/services/stripe-service";

export default defineComponent({
  name: "Secure payment",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      planId: '',
      coupon: null,
      error: ''
    }
  },
  methods: {
    doPlans() {
      this.$router.push('/plans')
    },
    doSignOut() {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    },
    doPayment() {
      StripeService.getPaymentUrl(this.planId).then((stripeUrl) => {
        if (stripeUrl != "false") {
          if (this.coupon != null && this.coupon.valid == true) {
            if (localStorage.getItem('coupon')) {
              localStorage.setItem('coupon', null)
            }
            window.location = stripeUrl + "&coupon=" + this.coupon.couponCode + "&token=" + localStorage.getItem('access_token') + "&redirectUrl=" + location.hostname
          } else {
            window.location = stripeUrl + "&token=" + localStorage.getItem('access_token') + "&redirectUrl=" + location.hostname
          }
        }
      }).catch((error) => {
        this.error = 'Error: ' + error
      })
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userProfile') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    }
    if (localStorage.getItem('chosenPlan')) {
      this.planId = localStorage.getItem('chosenPlan')
    } else {
      this.$router.push('/plans')
    }
    if (localStorage.getItem('coupon')) this.coupon = JSON.parse(localStorage.getItem('coupon'))
  },
})
</script>

<style>
@import '../assets/style.css';
</style>
